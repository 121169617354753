/* Parent container for centering */
.loader-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f8f9fa; /* Subtle background */
}

/* Animated loader container */
.animated-loader {
    animation: fadeIn 2s ease-in-out; /* Smooth fade-in animation */
}

/* Logo pulsing effect */
.pulse-logo {
    height: 90px;
    width: 110px;
    animation: pulse 2s infinite ease-in-out; /* Pulsing effect */
    transform-origin: center; /* Center scaling */
}

/* Fade-in animation keyframes */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Pulse animation keyframes */
@keyframes pulse {
    0% {
        transform: scale(1); /* Original size */
    }
    50% {
        transform: scale(1.2); /* Enlarged */
    }
    100% {
        transform: scale(1); /* Back to original size */
    }
}
