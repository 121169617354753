//
// light-mode.scss
//

// Dark Variable

// Dark Grays
$dark-grays: (
  "100": #282e38,
  "200": #313844,
  "300": #3a4250,
  "400": #6c757d,
  "500": #98a6ad,
  "600": #adb5bd,
  "700": #dee2e6,
  "800": #f7f7f7,
  "900": #f1f5f7,
);

// Dark Theme Color Map
$dark-theme-colors: (
  "primary": $primary,
  "secondary": map-get($dark-grays, "400"),
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": map-get($dark-grays, "300"),
  "dark": map-get($dark-grays, "800"),
  "pink": $pink,
  "purple": $purple,
  "blue": $blue,
);

body[data-layout-color="dark"] {
  // <=============================================>
  // Bootstrap variable
  // <=============================================>

  // Body Variable
  --#{$variable-prefix}body-bg: #282e38;
  --#{$variable-prefix}body-color: #{map-get($dark-grays, "600")};

  // Link Variable
  --#{$variable-prefix}link-color: #{$primary};
  --#{$variable-prefix}link-hover-color: #{shade-color($primary, $link-shade-percentage)};

  // Border Variable
  --#{$variable-prefix}border-color: #{map-get($dark-grays, "300")};

  // scss-docs-start box-shadow-variables
  --#{$variable-prefix}box-shadow: 0px 0px 35px 0px #{rgba(66, 72, 80, 0.15)};
  --#{$variable-prefix}box-shadow-sm: 0 0.75rem 6rem #{rgba(56, 65, 74, 0.03)};
  --#{$variable-prefix}box-shadow-lg: 0 0 45px 0 #{rgba($black, 0.12)};
  --#{$variable-prefix}box-shadow-inset: inset 0 1px 2px #{rgba($black, 0.075)};

  // scss-docs-end box-shadow-variables
  --#{$variable-prefix}component-active-color: #{$white};
  --#{$variable-prefix}component-active-bg: #{$primary};

  // scss-docs-start type-variables
  --#{$variable-prefix}text-muted: #{map-get($dark-grays, "600")};
  --#{$variable-prefix}blockquote-footer-color: #{map-get($dark-grays, "600")};
  --#{$variable-prefix}hr-color: #3a4250;

  --#{$variable-prefix}mark-bg: #fcf8e3;

  // scss-docs-start table-variables
  --#{$variable-prefix}table-color: #{map-get($dark-grays, "500")};
  --#{$variable-prefix}table-bg: transparent;
  --#{$variable-prefix}table-accent-bg: transparent;
  --#{$variable-prefix}table-striped-color: #{$table-color};
  --#{$variable-prefix}table-striped-bg: #{rgba(map-get($dark-grays, "300"), 1)};
  --#{$variable-prefix}table-active-color: #{$table-color};
  --#{$variable-prefix}table-active-bg: #{rgba(map-get($dark-grays, "300"), $table-active-bg-factor)};
  --#{$variable-prefix}table-hover-color: #{$table-color};
  --#{$variable-prefix}table-hover-bg: #{rgba(map-get($dark-grays, "300"), $table-hover-bg-factor)};
  --#{$variable-prefix}table-border-color: #{$border-color};
  --#{$variable-prefix}table-group-separator-color: #{map-get($dark-grays, "300")};
  --#{$variable-prefix}table-caption-color: #{$text-muted};

  // scss-docs-start input-btn-variables
  --#{$variable-prefix}input-btn-focus-color: #{rgba($primary, $input-btn-focus-color-opacity)};

  // scss-docs-start btn-variables
  --#{$variable-prefix}btn-active-box-shadow: inset 0 3px 5px #{rgba($black, 0.125)};
  --#{$variable-prefix}btn-link-color: #{$link-color};
  --#{$variable-prefix}btn-link-hover-color: #{$link-hover-color};
  --#{$variable-prefix}btn-link-disabled-color: #{map-get($dark-grays, "500")};

  // scss-docs-start form-text-variables
  --#{$variable-prefix}form-text-color: #{$text-muted};

  // scss-docs-start form-input-variables
  --#{$variable-prefix}input-bg: #{lighten(map-get($dark-grays, "200"), 2.5%)};
  --#{$variable-prefix}input-disabled-bg: #{lighten(map-get($dark-grays, "200"), 2%)};
  --#{$variable-prefix}input-color: #{map-get($dark-grays, "500")};
  --#{$variable-prefix}input-border-color: #{lighten(map-get($dark-grays, "200"), 8%)};
  --#{$variable-prefix}input-box-shadow: #{$box-shadow-inset};
  --#{$variable-prefix}input-focus-bg: #{$input-bg};
  --#{$variable-prefix}input-focus-border-color: #{lighten(lighten(map-get($dark-grays, "300"), 2%), 10%)};
  --#{$variable-prefix}input-focus-color: #{$input-color};
  --#{$variable-prefix}input-placeholder-color: #{map-get($dark-grays, "500")};
  --#{$variable-prefix}input-plaintext-color: #{map-get($dark-grays, "500")};

  // scss-docs-start form-check-variables
  --#{$variable-prefix}form-check-input-bg: #{$input-bg};
  --#{$variable-prefix}form-check-input-border: #{$border-width solid #{map-get($dark-grays, "400")}};
  --#{$variable-prefix}form-check-input-checked-color: #{$component-active-color};
  --#{$variable-prefix}form-check-input-checked-bg-color: #{$component-active-bg};
  --#{$variable-prefix}form-check-input-checked-border-color: #{$form-check-input-checked-bg-color};
  --#{$variable-prefix}form-check-input-indeterminate-color: #{$component-active-color};
  --#{$variable-prefix}form-check-input-indeterminate-bg-color: #{$component-active-bg};
  --#{$variable-prefix}form-check-input-indeterminate-border-color: #{$form-check-input-indeterminate-bg-color};

  // scss-docs-start form-switch-variables
  --#{$variable-prefix}form-switch-color: #{map-get($dark-grays, "400")};

  // scss-docs-start input-group-variables
  --#{$variable-prefix}input-group-addon-color: #{$input-color};
  --#{$variable-prefix}input-group-addon-bg: #{map-get($dark-grays, "200")};
  --#{$variable-prefix}input-group-addon-border-color: #{$input-border-color};

  // scss-docs-start form-select-variables
  --#{$variable-prefix}form-select-color: #{$input-color};
  --#{$variable-prefix}form-select-bg: #{$input-bg};
  --#{$variable-prefix}form-select-disabled-color: #{map-get($dark-grays, "600")};
  --#{$variable-prefix}form-select-disabled-bg: #{map-get($dark-grays, "200")};
  --#{$variable-prefix}form-select-disabled-border-color: #{$input-disabled-border-color};
  --#{$variable-prefix}form-select-indicator-color: #{map-get($dark-grays, "800")};

  // scss-docs-start form-range-variables
  --#{$variable-prefix}form-range-track-bg: #{map-get($dark-grays, "300")};
  --#{$variable-prefix}form-range-track-box-shadow: inset 0 0.25rem 0.25rem #{rgba($black, 0.1)};
  --#{$variable-prefix}form-range-thumb-box-shadow: 0 0.1rem 0.25rem #{rgba($black, 0.1)};
  --#{$variable-prefix}form-range-thumb-active-bg: #{tint-color($primary, 70%)};
  --#{$variable-prefix}form-range-thumb-disabled-bg: #{map-get($dark-grays, "500")};

  // scss-docs-start form-file-variables
  --#{$variable-prefix}form-file-button-color: #{$input-color};
  --#{$variable-prefix}form-file-button-bg: #{map-get($dark-grays, "200")};
  --#{$variable-prefix}form-file-button-hover-bg: #{shade-color(map-get($dark-grays, "200"), 5%)};

  // scss-docs-start nav-variables
  --#{$variable-prefix}nav-link-disabled-color: #{map-get($dark-grays, "600")};
  --#{$variable-prefix}nav-tabs-border-color: #{map-get($dark-grays, "300")};
  --#{$variable-prefix}nav-tabs-link-hover-border-color: #{map-get($dark-grays, "200")} #{map-get($dark-grays, "200")} #{$nav-tabs-border-color};
  --#{$variable-prefix}nav-tabs-link-active-color: #{map-get($dark-grays, "700")};
  --#{$variable-prefix}nav-tabs-link-active-bg: #{map-get($dark-grays, "200")};
  --#{$variable-prefix}nav-tabs-link-active-border-color: #{map-get($dark-grays, "300") map-get($dark-grays, "300")
    $nav-tabs-link-active-bg};

  // scss-docs-start navbar-theme-variables
  --#{$variable-prefix}navbar-dark-color: #{rgba($white, 0.55)};
  --#{$variable-prefix}navbar-dark-hover-color: #{rgba($white, 0.75)};
  --#{$variable-prefix}navbar-dark-active-color: #{$white};
  --#{$variable-prefix}navbar-dark-disabled-color: #{rgba($white, 0.25)};
  --#{$variable-prefix}navbar-dark-toggler-border-color: #{rgba($white, 0.1)};
  --#{$variable-prefix}navbar-light-color: #{rgba($black, 0.55)};
  --#{$variable-prefix}navbar-light-hover-color: #{rgba($white, 0.7)};
  --#{$variable-prefix}navbar-light-active-color: #{rgba($black, 0.9)};
  --#{$variable-prefix}navbar-light-disabled-color: #{rgba($black, 0.3)};

  // scss-docs-start dropdown-variables
  --#{$variable-prefix}dropdown-color: #{$body-color};
  --#{$variable-prefix}dropdown-bg: #{map-get($dark-grays, "200")};
  --#{$variable-prefix}dropdown-border-color: #{lighten(map-get($dark-grays, "200"), 4%)};
  --#{$variable-prefix}dropdown-divider-bg: #{lighten(map-get($dark-grays, "200"), 4%)};
  --#{$variable-prefix}dropdown-box-shadow: 0 0.5rem 1rem #{rgba($black, 0.175)};
  --#{$variable-prefix}dropdown-link-color: #{map-get($dark-grays, "600")};
  --#{$variable-prefix}dropdown-link-hover-color: #{shade-color(map-get($dark-grays, "900"), 12%)};
  --#{$variable-prefix}dropdown-link-hover-bg: #{map-get($dark-grays, "300")};
  --#{$variable-prefix}dropdown-link-active-color: #{darken(map-get($dark-grays, "900"), 5%)};
  --#{$variable-prefix}dropdown-link-active-bg: #{map-get($dark-grays, "300")};
  --#{$variable-prefix}dropdown-link-disabled-color: #{map-get($dark-grays, "600")};
  --#{$variable-prefix}dropdown-header-color: #{map-get($dark-grays, "600")};

  // scss-docs-start dropdown-dark-variables
  --#{$variable-prefix}dropdown-dark-color: #{map-get($dark-grays, "300")};
  --#{$variable-prefix}dropdown-dark-bg: #{map-get($dark-grays, "800")};
  --#{$variable-prefix}dropdown-dark-border-color: #{$dropdown-border-color};
  --#{$variable-prefix}dropdown-dark-divider-bg: #{$dropdown-divider-bg};
  --#{$variable-prefix}dropdown-dark-link-color: #{$dropdown-dark-color};
  --#{$variable-prefix}dropdown-dark-link-hover-color: #{$white};
  --#{$variable-prefix}dropdown-dark-link-hover-bg: #{rgba($white, 0.15)};
  --#{$variable-prefix}dropdown-dark-link-active-color: #{$dropdown-link-active-color};
  --#{$variable-prefix}dropdown-dark-link-active-bg: #{$dropdown-link-active-bg};
  --#{$variable-prefix}dropdown-dark-link-disabled-color: #{map-get($dark-grays, "500")};
  --#{$variable-prefix}dropdown-dark-header-color: #{map-get($dark-grays, "500")};

  // scss-docs-start pagination-variables
  --#{$variable-prefix}pagination-color: #{map-get($dark-grays, "800")};
  --#{$variable-prefix}pagination-bg: #{lighten(map-get($dark-grays, "200"), 5%)};
  --#{$variable-prefix}pagination-border-color: #{lighten(map-get($dark-grays, "300"), 5%)};
  --#{$variable-prefix}pagination-focus-color: #{$link-hover-color};
  --#{$variable-prefix}pagination-focus-bg: #{map-get($dark-grays, "200")};
  --#{$variable-prefix}pagination-focus-box-shadow: #{$input-btn-focus-box-shadow};
  --#{$variable-prefix}pagination-hover-color: #{map-get($dark-grays, "800")};
  --#{$variable-prefix}pagination-hover-bg: #{map-get($dark-grays, "300")};
  --#{$variable-prefix}pagination-hover-border-color: #{map-get($dark-grays, "300")};
  --#{$variable-prefix}pagination-disabled-color: #{map-get($dark-grays, "600")};
  --#{$variable-prefix}pagination-disabled-bg: #{map-get($dark-grays, "200")};
  --#{$variable-prefix}pagination-disabled-border-color: #{map-get($dark-grays, "300")};

  // scss-docs-start card-variables
  --#{$variable-prefix}card-border-color: #{map-get($dark-grays, "200")};
  --#{$variable-prefix}card-box-shadow: #{null};
  --#{$variable-prefix}card-cap-bg: #{map-get($dark-grays, "200")};
  --#{$variable-prefix}card-bg: #{map-get($dark-grays, "200")};

  // scss-docs-start accordion-variables
  --#{$variable-prefix}accordion-color: #{$body-color};
  --#{$variable-prefix}accordion-border-color: #{rgba($black, 0.125)};
  --#{$variable-prefix}accordion-button-active-bg: #{tint-color($primary, 90%)};
  --#{$variable-prefix}accordion-button-active-color: #{shade-color($primary, 10%)};
  --#{$variable-prefix}accordion-button-focus-border-color: #{$input-focus-border-color};
  --#{$variable-prefix}accordion-button-focus-box-shadow: #{$btn-focus-box-shadow};

  // scss-docs-start tooltip-variables
  --#{$variable-prefix}tooltip-color: #{$white};
  --#{$variable-prefix}tooltip-bg: #{$black};

  // scss-docs-start popover-variables
  --#{$variable-prefix}popover-bg: #{map-get($dark-grays, "300")};
  --#{$variable-prefix}popover-border-color: #{map-get($dark-grays, "300")};
  --#{$variable-prefix}popover-header-bg: #{map-get($dark-grays, "100")};
  --#{$variable-prefix}popover-header-color: #{$headings-color};
  --#{$variable-prefix}popover-body-color: #{$white};
  --#{$variable-prefix}popover-arrow-color: #{map-get($dark-grays, "300")};
  --#{$variable-prefix}popover-arrow-outer-color: #{fade-in(map-get($dark-grays, "300"), 0.05)};

  // scss-docs-start toast-variables
  --#{$variable-prefix}toast-background-color: #{rgba($white, 0.06)};
  --#{$variable-prefix}toast-border-color: #{rgba($black, 0.1)};
  --#{$variable-prefix}toast-header-color: #{map-get($dark-grays, "600")};
  --#{$variable-prefix}toast-header-background-color: #{rgba($white, 0.06)};
  --#{$variable-prefix}toast-header-border-color: #{rgba($black, 0.05)};

  // scss-docs-start badge-variables
  --#{$variable-prefix}badge-color: #{$white};

  // scss-docs-start modal-variables
  --#{$variable-prefix}modal-content-bg: #{map-get($dark-grays, "200")};
  --#{$variable-prefix}modal-content-box-shadow-xs: 0 0.25rem 0.5rem #{rgba($black, 0.5)};
  --#{$variable-prefix}modal-content-box-shadow-sm-up: 0 0.5rem 1rem #{rgba($black, 0.5)};
  --#{$variable-prefix}modal-backdrop-bg: #{map-get($dark-grays, "900")};
  --#{$variable-prefix}modal-header-border-color: #{$border-color};
  --#{$variable-prefix}modal-footer-border-color: #{$border-color};

  // scss-docs-start progress-variables
  --#{$variable-prefix}progress-bg: #{map-get($dark-grays, "300")};
  --#{$variable-prefix}progress-box-shadow: #{$box-shadow-inset};
  --#{$variable-prefix}progress-bar-color: #{$white};
  --#{$variable-prefix}progress-bar-bg: #{$primary};

  // scss-docs-start list-group-variables
  --#{$variable-prefix}list-group-color: #{null};
  --#{$variable-prefix}list-group-bg: #{map-get($dark-grays, "200")};
  --#{$variable-prefix}list-group-border-color: #{rgba($black, 0.125)};
  --#{$variable-prefix}list-group-hover-bg: #{map-get($dark-grays, "100")};
  --#{$variable-prefix}list-group-disabled-color: #{map-get($dark-grays, "600")};
  --#{$variable-prefix}list-group-disabled-bg: #{map-get($dark-grays, "200")};
  --#{$variable-prefix}list-group-action-color: #{map-get($dark-grays, "700")};
  --#{$variable-prefix}list-group-action-active-color: #{$body-color};
  --#{$variable-prefix}list-group-action-active-bg: #{map-get($dark-grays, "200")};

  // scss-docs-start thumbnail-variables
  --#{$variable-prefix}thumbnail-bg: #{lighten(map-get($dark-grays, "200"), 2%)};
  --#{$variable-prefix}thumbnail-border-color: #{lighten(lighten(map-get($dark-grays, "200"), 2%), 4%)};
  --#{$variable-prefix}thumbnail-box-shadow: 0 1px 2px #{rgba($black, 0.075)};

  // scss-docs-start figure-variables
  --#{$variable-prefix}figure-caption-color: #{map-get($dark-grays, "600")};

  // scss-docs-start breadcrumb-variables
  --#{$variable-prefix}breadcrumb-divider-color: #{map-get($dark-grays, "400")};
  --#{$variable-prefix}breadcrumb-active-color: #{map-get($dark-grays, "500")};

  // scss-docs-start carousel-variables
  --#{$variable-prefix}carousel-control-color: #{$white};
  --#{$variable-prefix}carousel-indicator-active-bg: #{$white};
  --#{$variable-prefix}carousel-caption-color: #{$white};
  --#{$variable-prefix}carousel-dark-indicator-active-bg: #{$black};
  --#{$variable-prefix}carousel-dark-caption-color: #{$black};

  // scss-docs-start close-variables
  --#{$variable-prefix}btn-close-color: #cccecf;

  // Code
  --#{$variable-prefix}code-color: #{$pink};

  --#{$variable-prefix}kbd-color: #{$white};
  --#{$variable-prefix}kbd-bg: #{map-get($dark-grays, "900")};
  --#{$variable-prefix}pre-color: #{null};

  // <=============================================>
  // Custome variable
  // <=============================================>

  // Left Sidebar
  --#{$variable-prefix}bg-leftbar: #{$white};
  --#{$variable-prefix}bg-leftbar-dark: #{map-get($dark-grays, "200")};
  --#{$variable-prefix}bg-leftbar-brand: #4a81d4;
  --#{$variable-prefix}bg-leftbar-yellow: #f9c851;
  --#{$variable-prefix}bg-leftbar-green: rgb(99 217 8);

  // user box
  --#{$variable-prefix}left-menu-user-box-border: #f5f5f5;
  --#{$variable-prefix}left-menu-dark-user-box-border: #{rgba($white, 0.2)};

  // Dark leftbar menu color
  --#{$variable-prefix}menu-item-color-dark: #9097a7;
  --#{$variable-prefix}menu-item-hover-color-dark: #c8cddc;
  --#{$variable-prefix}menu-item-active-color-dark: #{$white};

  //Menu item
  --#{$variable-prefix}menu-item: #6e768e;
  --#{$variable-prefix}menu-item-hover: #71b6f9;
  --#{$variable-prefix}menu-item-active: #71b6f9;
  --#{$variable-prefix}menu-sub-item-active: #71b6f9;

  // Horizontal Menu Item
  --#{$variable-prefix}hori-menu-item-color: #919eab;

  //Rightbar Width
  --#{$variable-prefix}rightbar-bg: #323a46;

  //Topbar Background
  --#{$variable-prefix}bg-topbar-light: #353d4a;
  --#{$variable-prefix}bg-topbar-dark: #2c333e;
  --#{$variable-prefix}bg-topbar-gradient: #5b69bc;

  // Leftbar item shadow (Condensed item)
  --#{$variable-prefix}box-shadow-condensed: 3px 5px 10px 0 rgba(50, 58, 66);

  // Page title
  --#{$variable-prefix}page-title-color: #f8f9fa;

  // Topbar Search
  --#{$variable-prefix}topbar-search-bg: #323a46;
  --#{$variable-prefix}topbar-search-bg-dark: #323a46;

  // Footer
  --#{$variable-prefix}footer-bg: #2c333e;
  --#{$variable-prefix}footer-color: #adb5bd;
  --#{$variable-prefix}footer-link-hover-color: #a2abb4;

  // Logo box
  --#{$variable-prefix}logo-box-background: #{$white};

  // User authentication Background
  --#{$variable-prefix}auth-bg: #3e4852;
  --#{$variable-prefix}auth-bg-alt: #3e4852;

  // Chat widget
  --#{$variable-prefix}chat-primary-user-bg: #404c58;
  --#{$variable-prefix}chat-secondary-user-bg: #404c58;

  // Card overlay
  --#{$variable-prefix}card-overlay-bg: #{rgba($white, 0.8)};

  // Input light gray
  --#{$variable-prefix}input-light-bg: #3b4550;

  // Email
  --#{$variable-prefix}email-msg-container-bg: #333a47;

  // Apex chart
  --#{$variable-prefix}apex-grid-color: #3e4852;
}
